import { SimpleGrid, Pagination, LoadingOverlay} from "@mantine/core";
import { useState, useEffect } from "react";
import {getAllEntries} from "../../utilities/firebase/getEntries";
import JournalEntryCard from "../UI/JournalEntryCard";
import JournalHomeBar from "./JournalHomeBar";

const ENTRIES_PER_PAGE = 16;


const JournalList = () => {
  const [entries, setEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentEntries, setCurrentEntries] = useState([]);
  const [selectedDateRange, setSelectedDateRange]=useState([]);
  const [loading, setLoading]=useState(false);


  useEffect(() => {
    getJournalEntries();
  }, []);

  useEffect(() => { 
    if(filteredEntries && filteredEntries.length>0){
      const startFrom = (activePage - 1) * ENTRIES_PER_PAGE;
      const endAt = (activePage) * ENTRIES_PER_PAGE;
      setCurrentEntries(filteredEntries.slice(startFrom,endAt));
      const tot = Math.ceil(filteredEntries.length/ENTRIES_PER_PAGE);  
      setTotalPages(tot);
    }
  }, [activePage, filteredEntries]);


  const getJournalEntries = async () => {
    setLoading(true);
    const result = await getAllEntries();
    if(result && result.length>0){
      setEntries(result);
      setFilteredEntries(result);
      //move
    
    }
    setLoading(false);
  };

  const onFilterDateRangeChange = (dates)=>{
    setSelectedDateRange(dates);
    if(dates[0]!=null && dates[1]!=null){
      const startDate = new Date(dates[0]);
      const endDate = new Date(dates[1]);
      const newEntries = entries.filter(entry =>{
        const entryDate = new Date(entry.date);
        if(entryDate >= startDate && entryDate<= endDate)
          return true;
        else
          return false;
      });
      setFilteredEntries(newEntries);
    }else if(dates[0]===null && dates[1]===null){
      setFilteredEntries(entries);
    }
  }

  return (
    <>
    <JournalHomeBar entries={filteredEntries} selectedDateRange={selectedDateRange}  setSelectedDateRange={onFilterDateRangeChange} />
      <LoadingOverlay visible={loading} />
     <SimpleGrid cols={4}>
      {currentEntries.map((entry) => (
        <JournalEntryCard
            key={entry.id}
            entry={entry}
        />
      ))}
    </SimpleGrid>
    <Pagination   style={{ marginTop: 15 }} total={totalPages} page={activePage} onChange={setActivePage} />
    </>
   
  );
};

export default JournalList;
