import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useState } from 'react';
import Login from './pages/Login';
import Home from './pages/Home';
import ViewEntry from './pages/ViewEntry';
import Create from './pages/Create';
import Edit from './pages/Edit';
import { NotificationsProvider } from '@mantine/notifications';
import {MantineProvider, ColorSchemeProvider} from '@mantine/core';
import Lab from './pages/Lab';
import { getAuth, onAuthStateChanged } from "firebase/auth";

function App() {
  // const authCtx = useContext(AuthContext);
  // const isLoggedIn = authCtx.isLoggedIn;
  const [user, setUser]= useState();

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
      localStorage.setItem('authUser', JSON.stringify(user));
    } else {
      setUser(null);
      localStorage.removeItem('authUser');
    }
  });

  const [colorScheme, setColorScheme] = useState('light');
  const toggleColorScheme = (value) => setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={{colorScheme}} withGlobalStyles>
        <NotificationsProvider>
          <Routes>
            {!user && <Route path="/login" element={<Login />}/> }
            {user && <Route path="/home" element={<Home />}/> }
            {user && <Route path="/create" element={<Create />}/> }
            {user && <Route path="/entry/:slug" element={<ViewEntry />}/> }
            {user && <Route path="/entry/:slug/edit" element={<Edit />}/> }
            {user && <Route path="/lab" element={<Lab />}/> }
            <Route path="*" element={user ? <Navigate to="/home" /> : <Navigate to="/login" /> }/>
          </Routes>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider >
  );
}

export default App;
