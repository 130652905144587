import {Card, Badge, Grid} from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";

const JournalHomeBar = ({entries, selectedDateRange, setSelectedDateRange}) => {
  return (
    <Card style={{marginBottom:"20px"}} shadow="sm" padding="lg">
        <Grid>
            <Grid.Col span={6}>
                <h3>Welcome To Your Journal</h3>
                Total Entries:  <Badge>{entries.length}</Badge>
            </Grid.Col>
            <Grid.Col span={6}>
                <DateRangePicker
                    value={selectedDateRange}
                    placeholder="Select a date range"
                    onChange={setSelectedDateRange}
                    label="Filter"
                />
            </Grid.Col>
        </Grid>
  
   
  </Card>
  )
}

export default JournalHomeBar