import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyACupTvEvK0QfGUa2RF1Sp-cFmec76PB4k",
  authDomain: "myjournal-94042.firebaseapp.com",
  projectId: "myjournal-94042",
  storageBucket: "myjournal-94042.appspot.com",
  messagingSenderId: "856427158528",
  appId: "1:856427158528:web:c26a8631e54c5fbca40ce5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore();
export const storage = getStorage();

export default db;