import LoginPage from "../components/login/LoginPage";


const Login = () => {


  return (
    <LoginPage />
  );
};

export default Login;
