import ImportEntries from "../components/lab/ImportEntries";
import DashboardLayout from "../components/layout/DashboardLayout";

const Lab = () => {
  return (
    <DashboardLayout>
        <ImportEntries />
    </DashboardLayout>
  )
}

export default Lab;