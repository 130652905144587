import { Dialog, Text, Button, Title } from "@mantine/core";

const DeleteEntryDialog = (props) => {

  return (
    <Dialog
        opened={props.opened}
        withCloseButton
        onClose={props.onCloseClicked}
        size="lg"
        radius="md"
    >
        <Title order={4}>Are You Sure You Wish To Delete This Entry?</Title>

        <Text size="xs" style={{ marginBottom: 10 }} >
            This Cannot Be Undone
        </Text>
        <Button onClick={props.onDeleteClicked} variant="subtle" color="red" fullWidth>
            Yes, Burn It!
        </Button>
        <Button onClick={props.onCloseClicked} variant="subtle" color="green" fullWidth>
            No, Go Back.
        </Button>
    </Dialog>
  )
}

export default DeleteEntryDialog