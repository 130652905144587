import { useState } from "react";
import { Navbar, ThemeIcon, Group, Drawer, Burger, ActionIcon} from "@mantine/core";
import { IoHomeOutline, IoPencil, IoFlask } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import SideMenu from '../interface/SideMenu';
import styles from './SideNavBar.module.css';
import DarkModeToggle from "./DarkModeToggle";


const SideNavBar = () => {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [burgerOpened, setBurgerOpened] = useState(false);

  const burgerClickHandler = () => {
    setBurgerOpened(true);
    setDrawerOpened(true);
  };

  const drawerCloseHandler = () => {
    setDrawerOpened(false);
    setBurgerOpened(false);
  };

  return (
    <Navbar
      fixed
      position={{ top: 0, left: 0 }}
      height="100%"
      width={{ base: 100 }}
      padding="xl"
    >
        <Drawer
            opened={drawerOpened}
            onClose={drawerCloseHandler}
            title="Menu"
            size="sm"
            padding="md"
          >
            <SideMenu />
        </Drawer>
        <div className={styles.navbar}>
          <div>
          <Burger
              color="#3498db"
              opened={burgerOpened}
              onClick={burgerClickHandler}
            />
          </div>
          <div>
          <Group direction="column" spacing="xl" height="100%">
            <ActionIcon 
              component={NavLink} 
              to="/home" 
              variant="light" 
              color="orange" 
              size="xl"
              title="Home">
              <IoHomeOutline />
            </ActionIcon>
            <ActionIcon component={NavLink} to="/create" variant="light" color="pink" size="xl" title="Write">
              <IoPencil />
            </ActionIcon>
            <ActionIcon component={NavLink} to="/lab" variant="light" color="green" size="xl" title="Lab">
              <IoFlask />
            </ActionIcon>
        </Group>
          </div>
          <div>
            <DarkModeToggle />
          </div>
        </div>
      {/* <Navbar.Section>
            <Burger
              color="#3498db"
              opened={burgerOpened}
              onClick={burgerClickHandler}
            />
      </Navbar.Section>
      <Navbar.Section  grow mt="lg">
       
      </Navbar.Section>
      <Navbar.Section  grow mt="lg">
     
      </Navbar.Section> */}
    </Navbar>
  );
};

export default SideNavBar;
