import EntryForm from "./EntryForm";
import {createJournalEntry} from '../../utilities/journal/setJournal';
import { useNotifications } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";

const WriteEntry = () => {
  const navigate = useNavigate();
  const notifications = useNotifications();

  const formSubmitHandler = async (title, date, body, location, imageUrl, emotion)=>{
    const result = await createJournalEntry(title, date, body, location, imageUrl, emotion);
    if(result){
      switch(result){
          case "DATABASE_POST_SUCCESS":  
                    notifications.showNotification({
                        title: 'Rejoice!',
                        message: 'The Entry Has Been Successfuly Saved!',
                        autoClose: 5000,
                        color: 'green',
                    });
                    navigate('/home',{replace:true}); 
                    break;
          case "DATABASE_POST_ERROR": 
                    notifications.showNotification({
                        title: 'Ah Crap!',
                        message: 'The database hates you and did not save the entry!',
                        autoClose: 5000,
                        color: 'red',
                    });
                    break;
          case "DECRYPTION_ERROR":  
                    notifications.showNotification({
                        title: 'Ah Crap!',
                        message: 'The entry could not be encrypted. Why would that happen?!',
                        autoClose: 5000,
                        color: 'red',
                    }); 
                    break;
          default:
                  notifications.showNotification({
                      title: 'Ah Crap!',
                      message: 'There was an unknown error! ',
                      autoClose: 5000,
                      color: 'red',
                  }); 
      }
     }else{
                notifications.showNotification({
                  title: 'Ah Crap!',
                  message: 'There was an unknown error! ',
                  autoClose: 5000,
                  color: 'red',
              }); 
     }
  }



  const discardClickHandler =()=>{
    navigate('/home',{replace:true});
  }


  return (
    <>
        <EntryForm onSubmitHandler={formSubmitHandler} onDiscardClicked={discardClickHandler} />
    </>
  )
}

export default WriteEntry