import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/UI/interface/ScrollToTop';

ReactDOM.render(
    <BrowserRouter>
      <ScrollToTop />
        <App />
    </BrowserRouter>
  ,
  document.getElementById('root')
);
