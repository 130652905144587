import { Avatar, UnstyledButton, ThemeIcon, Group,Text } from '@mantine/core';
import styles from './SideMenu.module.css';
import { IoHomeOutline, IoPencil, IoFlask } from "react-icons/io5";
import {MdOutlineDirectionsRun} from "react-icons/md";
import { logOutOfFireBase } from '../../../utilities/firebase/auth';


const SideMenu = () => {

    const signOut = async()=>{
        await logOutOfFireBase();
    }
  return (
    <div className={styles.container}>
        <Avatar style={{margin:'auto'}} radius="xl" alt="no image here" color="indigo" size="xl" />
        <ul className={styles.menu}>

            <li className={styles.menu__item}>
                <UnstyledButton>
                    <Group position="apart">
                        <ThemeIcon variant="light" color="orange"  size="xl"><IoHomeOutline /></ThemeIcon>
                        <Text size='md'>Home</Text>
                    </Group>
                </UnstyledButton>
            </li>
            <li className={styles.menu__item}>
                <UnstyledButton>
                    <Group position="apart">
                        <ThemeIcon  variant="light" size="xl" color="pink"><IoPencil /></ThemeIcon>
                        <Text size='md'>Write</Text>
                    </Group>
                </UnstyledButton>
             </li>
            <li className={styles.menu__item}>
                <UnstyledButton>
                    <Group position="apart" >
                        <ThemeIcon variant="light" size="xl" color="teal"><IoFlask /></ThemeIcon>
                        <Text size='md'>Lab</Text>
                    </Group>
                </UnstyledButton>
            </li>
            <li className={styles.menu__item}>
                <UnstyledButton onClick={signOut}>
                    <Group position="apart" >
                        <ThemeIcon variant="light" size="xl" color="grey"><MdOutlineDirectionsRun /></ThemeIcon>
                        <Text size='md'>Sign Out</Text>
                    </Group>
                </UnstyledButton>
            </li>
        </ul>
    </div>
  )
}

export default SideMenu