import { Card, Progress } from "@mantine/core";
import { useState, useEffect } from "react";
import styles from './ImportEntries.module.css';
import { Dropzone } from "@mantine/dropzone";
import { useNotifications } from "@mantine/notifications";
import { createJournalEntry } from "../../utilities/journal/setJournal";

const ImportEntries = () => {
    const [successCount, setSuccessCount] = useState(0);  
    const [failureCount, setFailureCount] = useState(0);  
    const [fileCount, setFileCount] = useState(0);  
    const [progress, setProgress]=useState(0);
    const notifications = useNotifications();

    useEffect(()=>{
        if(progress >= 100 && successCount+failureCount === fileCount ){
            notifications.showNotification({
                    title: 'Process Complete',
                    message: `${successCount} files successfully imported. ${failureCount} files failed to import.`,
                    autoClose: 3000,
                    color: 'blue',
                  });
                  setProgress(0);
                  setSuccessCount(0);
                  setFailureCount(0);
                  setFileCount(0);
        }
    },[progress, successCount, failureCount]);

    const fileSelectErrorHandler =(files)=>{
        notifications.showNotification({
          title: 'Hmm No!',
          message: "I can't read that file! Please upload a JSON file",
          autoClose: 3000,
          color: 'red',
        });
    }

    const fileSelectHandler =(files)=>{
        if(files && files.length > 0){
            setFileCount(files.length);
            files.forEach((file, index) => {
                const fileReader = new FileReader();
                fileReader.readAsText(file, "UTF-8");
                 fileReader.onload = async(e) => {
                    const importedData = e.target.result;
                    setProgress(((index+1)/files.length)*100);
                    const x = JSON.parse(importedData);
                    const body = x.text;
                    const location = x.address;
                    const d = new Date(x.date_journal);
                    const date = d.getFullYear() + '-'+ (d.getMonth()+1) +'-'+d.getDate();
                   const result = await createJournalEntry(date, date, body, location, null, null);
                   if(result){
                    switch(result){
                        case "DATABASE_POST_SUCCESS": setSuccessCount((prevCount)=>prevCount+1); break;
                        case "DATABASE_POST_ERROR": setFailureCount((prevCount)=>prevCount+1); break;
                        case "DECRYPTION_ERROR": setFailureCount((prevCount)=>prevCount+1); break;
                        default: setFailureCount((prevCount)=>prevCount+1);
                    }
                   }else{
                    setFailureCount((prevCount)=>prevCount+1);
                   }
                };
          });
        }
    }
    


  return (
    <Card shadow="sm" padding="lg">
        <div className={styles.layout}>
            <div>
                <h3>Import Journal Entries:</h3>
                <p className={styles.description}>Upload A JSON File To Import It</p>
            </div>
            <div>
                <Dropzone
                    loading={progress > 0 && progress<100}
                    onDrop={fileSelectHandler}
                    onReject={fileSelectErrorHandler}
                    accept={['application/json']}
                >
                {(status) => (
                    <p>Drop JSON files here or click to open explorer</p>
                    )}
                </Dropzone>
                {progress >0 && progress<100 && <Progress color="cyan" value={progress} animate />}
            </div>
        </div>
    </Card>
  )
}

export default ImportEntries