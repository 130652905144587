import { collection, getDocs, orderBy, query, where} from "firebase/firestore"; 
import db from "./firebase";

export const getAllEntries = async()=>{
    const entriesRef = collection(db, "entries");
    const querySnapshot = await getDocs(query(entriesRef,orderBy("date", "desc")));
    const myEntries = [];
    querySnapshot.forEach((doc) => {
        myEntries.push({id: doc.id, ...doc.data()});
    });
    return myEntries;
}

export const getSingleEntry = async(slug)=>{
    const result = query(collection(db,"entries"), where("slug", "==", slug));
    const querySnapshot = await getDocs(result);
    const myArray = [];
    querySnapshot.forEach((doc) => {
        myArray.push({id: doc.id, ...doc.data()});
    });
    if(myArray.length > 0)
        return myArray[0]
    else
        return null;
}

