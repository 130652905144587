import myConfig from "../../myConfig";

export const encryptData = async(body)=>{
    const res = await fetch(myConfig.ENCRYPTION_URL, {
        method:'POST',
        headers:{'Content-Type':'application/json'},
        body:JSON.stringify({
             operation:'ENCRYPT',
             text:body,
             password:myConfig.DEFAULT_RANDOM_CODE
        })
    });
    const output = await res.json();
    return output;
}

export const decryptData = async(body, flavor)=>{
    const res = await fetch(myConfig.ENCRYPTION_URL, {
        method:'POST',
        headers:{'Content-Type':'application/json'},
        body:JSON.stringify({
             operation:'DECRYPT',
             text:body,
             password:myConfig.DEFAULT_RANDOM_CODE,
             flavor: flavor
        })
    });
    const output = await res.json();
    return output;
}