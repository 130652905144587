const emotionList = [
    {
        value:"em1",
        label:"Shocked",
        image:"/images/emotions/Shocked.png",
        description:"What The Hell Was That?"
    },
    {
        value:"em2",
        label:"Furious",
        image:"/images/emotions/Furious.png",
        description:"Steam Coming Out Of My Ears"
    },
    {
        value:"em3",
        label:"Annoyed",
        image:"/images/emotions/Annoyed.png",
        description:"Can just ONE thing go my way?"
    },
    {
        value:"em4",
        label:"Depressed",
        image:"/images/emotions/Depressed.png",
        description:"Why must you test me, Cruel World?"
    },
    {
        value:"em5",
        label:"Frustrated",
        image:"/images/emotions/Frustrated.png",
        description:"For God's Sake!"
    },
    {
        value:"em6",
        label:"Confused",
        image:"/images/emotions/Confused.png",
        description:"To choose or not to choose"
    },
    {
        value:"em7",
        label:"Meh",
        image:"/images/emotions/Meh.png",
        description:"Meh!"
    },
    {
        value:"em8",
        label:"Calm",
        image:"/images/emotions/Calm.png",
        description:"Summer breeze makes me feel good"
    },
    {
        value:"em9",
        label:"Cheerful",
        image:"/images/emotions/Cheerful.png",
        description:"Feel like skipping across a farm"
    },
    {
        value:"em10",
        label:"Ecstatic",
        image:"/images/emotions/Ecstatic.png",
        description:"Over the moon!"
    },
    {
        value:"em11",
        label:"Naughty",
        image:"/images/emotions/Naughty.png",
        description:"A naughty tingling in my spine"
    },
];

export default emotionList;