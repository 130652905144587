import styles from './LoginPage.module.css';
import LoginForm from './LoginForm';

const LoginPage = () => {
  return (
    <div className={styles.layout}>
        <div className={styles['form-section']}>
            <LoginForm />
        </div>
        <div className={styles['banner-section']}>

        </div>
    </div>
  )
}

export default LoginPage