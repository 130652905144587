import React from 'react';
import {FaExclamationCircle} from "react-icons/fa";
import { Alert } from '@mantine/core';

const ShowError = ({message}) => {
  return (
    <Alert icon={<FaExclamationCircle size={16} />} title="Ah Crap!" color="red" variant="filled">
        {message}
    </Alert>
  )
}

export default ShowError