import { useParams } from 'react-router-dom';
import DashboardLayout from '../components/layout/DashboardLayout';
import EditEntry from '../components/write/EditEntry';

const Edit = () => {
    const slug = useParams().slug;
return (
    <DashboardLayout>
        <EditEntry slug={slug} />
    </DashboardLayout>
  )
}

export default Edit;