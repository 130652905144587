import {
  Card,
  Text,
  Image,
  Button,
  Badge,
  useMantineColorScheme,
} from "@mantine/core";
import { Link } from "react-router-dom";
import styles from "./JournalEntryCard.module.css";
import getRandomBackgroundUrl from "../../utilities/journal/getImageBackgrounds";

const JournalEntryCard = ({ entry }) => {
  const { colorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";


  let cardImage = entry.url;
  if (!cardImage) cardImage = getRandomBackgroundUrl();

  return (
    <Card shadow="sm" padding="xl" component={Link} to={`/entry/${entry.slug}`}>
      <Card.Section>
        <Image src={cardImage} height={180} alt="Card Image" />
      </Card.Section>
      <div>
        <div className={styles["title-section"]}>
          <div className={styles.title}>
            <Text weight={500} size="lg" color={dark ? "white" : "black"}>
              {entry.title}
            </Text>
          </div>
          <div>
            <Badge
              color={dark ? "cyan" : "pink"}
              variant={dark ? "filled" : "light"}
            >
              {entry.date}
            </Badge>
          </div>
        </div>
      </div>
      <Text color={dark ? "white" : "black"} size="sm">
        {entry.snippet}...
      </Text>
      <Button
        variant={dark ? "filled" : "light"}
        color={dark ? "yellow" : "blue"}
        fullWidth
        style={{ marginTop: 14 }}
      >
        Read
      </Button>
    </Card>
  );
};

export default JournalEntryCard;
