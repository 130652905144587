const getRandomBackgroundUrl = ()=>{
    const choice = Math.floor(Math.random() * 7);
    switch(choice){
        case 0: return "/images/backgrounds/card_background_1.jpg";
        case 1: return "/images/backgrounds/card_background_2.jpg";
        case 2: return "/images/backgrounds/card_background_3.jpg";
        case 3: return "/images/backgrounds/card_background_4.jpg";
        case 4: return "/images/backgrounds/card_background_5.jpg";
        case 5: return "/images/backgrounds/card_background_6.jpg";
        case 6: return "/images/backgrounds/card_background_7.jpg";
        default: return "/images/backgrounds/card_background_1.jpg";
    }
}

export default getRandomBackgroundUrl;