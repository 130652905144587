import { Grid } from '@mantine/core';
import SideNavBar from '../UI/interface/SideNavBar';
import styles from './DashboardLayout.module.css';

const DashboardLayout = (props) => {

  return (
    <Grid style={{margin:0}}>
      <Grid.Col span={1}>
          <SideNavBar />
      </Grid.Col>
      <Grid.Col span={11}>
          <div className={styles['main-content']}>
              {props.children}
          </div>
      </Grid.Col>
     </Grid>
  )
}

export default DashboardLayout