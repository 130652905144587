import { useState } from "react";
import { Card, Grid, Skeleton, Badge, Button, Divider } from "@mantine/core";
import ShowError from "../UI/ShowError";
import styles from "./EntryDetail.module.css";
import { FaRegEdit, FaTrashAlt, FaMapMarkerAlt } from "react-icons/fa";
import DeleteEntryDialog from "../UI/DeleteEntryDialog";
import EmotionGrid from "../UI/EmotionGrid";
import { getEmotionObject } from "../../utilities/emotions/emotionUtility";

const EntryDetail = (props) => {
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);

  const deleteButtonHandler = () => {
    setDeleteDialogOpened(false);
    props.onDeleteClicked();
  };

  const editButtonClickHandler = ()=>{
    props.onEditClicked();
  }

  let emotionObj = null;
  if(props.entry.emotion){
    emotionObj = getEmotionObject(props.entry.emotion);
  }

  return (
    <Grid>
      <Grid.Col span={8}>
        <Card shadow="sm" padding="xl">
          {props.error && <ShowError message={props.error} />}
          {props.isLoading ? (
            <Skeleton height={8} radius="xl" />
          ) : (
            <h2 className={styles.title}>{props.entry.title}</h2>
          )}
          <Divider className={styles.divider}  size="xs" />
          {props.entry.url &&
              <div className={styles.attachment}>
                <img  src={props.entry.url} alt="journal-attachment" />
              </div>
          }
          {props.isLoading ? (
            <>
              <Skeleton height={8} radius="xl" />
              <Skeleton height={8} mt={6} radius="xl" />
              <Skeleton height={8} mt={6} width="70%" radius="xl" />
            </>
          ) : (
            <p
              className={styles.body}
              dangerouslySetInnerHTML={{ __html: props.entry.body }}
            ></p>
          )}
        </Card>
      </Grid.Col>
      <Grid.Col span={4}>
        <Card style={{ height: "100%" }} shadow="sm" padding="xl">
            <div>
              <h4>Created On:</h4>
              {props.isLoading ? (
                <Skeleton height={8} radius="xl" width="70%" />
              ) : (
                <Badge color="orange" variant="outline">
                  {props.entry.date}
                </Badge>
              )}
            </div>
            <div style={{marginTop:"1em"}}>
              <h4>Location:</h4>
              {props.isLoading ? (
                <Skeleton height={8} radius="xl" width="70%" />
              ) : (
                <Badge color="violet" variant="outline" >
                  {props.entry.location !== ""
                    ? props.entry.location
                    : "The Void"}
                </Badge>
              )}
            </div>

          {emotionObj && 
          <div className={styles.emotions}>
           <h4>You Were Feeling:</h4>
            <EmotionGrid 
              image={`../${emotionObj.image}`} 
              label={emotionObj.label} 
              description={emotionObj.description} 
            />
          </div>
          }

          <div className={styles.actions}>
            <h3>Actions: </h3>
            <div className={styles.actions__items}>
              <Button
                leftIcon={<FaRegEdit />}
                onClick={editButtonClickHandler}
                variant="subtle"
                color="pink"
                fullWidth
              >
                Edit
              </Button>
              <Button
                onClick={() => setDeleteDialogOpened(true)}
                leftIcon={<FaTrashAlt />}
                variant="subtle"
                color="red"
                fullWidth
              >
                Delete
              </Button>
            </div>
          </div>

          {deleteDialogOpened && (
            <DeleteEntryDialog
              opened={deleteDialogOpened}
              onCloseClicked={() => setDeleteDialogOpened(false)}
              onDeleteClicked={()=> deleteButtonHandler()}
            />
          )}
        </Card>
      </Grid.Col>
    </Grid>
  );
};

export default EntryDetail;
