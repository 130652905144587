import {encryptData} from '../encrypt/handleData';
import { firebaseSaveJournalEntry, firebaseUpdateJournalEntry } from '../firebase/setEntries';


//* CREATE JOURNAL ENTRY 
export const createJournalEntry = async (title, date, body, location, url, emotion)=>{
    const finalObject = await CreateJournalEntryObject(title, date, body, location, url, emotion);
    if(finalObject){
       const result = await firebaseSaveJournalEntry(finalObject);
        if(result){
           return "DATABASE_POST_SUCCESS";
        }else
            return "DATABASE_POST_ERROR";
    }else
        return "DECRYPTION_ERROR";
}

//* UPDATE JOURNAL ENTRY 
export const updateJournalEntry = async (title, date, body, location, slug, url, emotion, id)=>{
    const finalObject = await UpdateJournalEntryObject(title, date, body, location, slug, url, emotion);
    if(finalObject){
       const result = await firebaseUpdateJournalEntry(id,finalObject);
        if(result){
           return "DATABASE_POST_SUCCESS";
        }else
            return "DATABASE_POST_ERROR";
    }else
        return "DECRYPTION_ERROR";
}


//* LOCAL METHOD: CREATE A ENTRY OBJECT
const CreateJournalEntryObject = async(title, date, body, location, url, emotion)=> {
    const slug = generateSlug(title);
    const snippet = generateSnippet(body);
    if(!emotion){
        emotion = null;
    }
    const encryptedData = await encryptData(body);
    if(encryptedData){
        return {
            title,
            date,
            slug,
            snippet,
            body: encryptedData.data,
            flavor: encryptedData.flavor,
            location,
            url,
            emotion
        };
    }else{
        return null;
    }
}

//* LOCAL METHOD: CREATE A ENTRY OBJECT FOR UPDATE PURPOSE
const UpdateJournalEntryObject = async(title, date, body, location, slug, url, emotion)=>{
    if(!emotion){
        emotion = null;
    }
    console.log("Inside UpdateJournalEntryObject. The Body is: ", body);
    const snippet = generateSnippet(body);
    const encryptedData = await encryptData(body);
    if(encryptedData){
        return {
            title,
            date,
            slug,
            snippet,
            body: encryptedData.data,
            flavor: encryptedData.flavor,
            location,
            url,
            emotion
        };
    }else{
        return null;
    }
}

//* LOCAL METHOD: CREATE A SLUG
const generateSlug= title =>{
    const cleanTitle=title.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    const newText=cleanTitle.replace(/ /g,'-');
    const randomNo=Math.floor(Math.random()*10000)+1;
    return newText+'-'+String(randomNo);
}

//* LOCAL METHOD: CREATE A SNIPPET
const generateSnippet = body =>{
    let cleanBody = body.replace(/<\/?[^>]+(>|$)/g, "");
    return cleanBody.slice(0,100);
}
