import JournalList from '../components/journal/JournalList';
import DashboardLayout from '../components/layout/DashboardLayout';

const Home = () => {
  return (
    <DashboardLayout>
      <JournalList />
    </DashboardLayout>
  )
}

export default Home