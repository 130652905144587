import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { LoadingOverlay } from "@mantine/core";
import EntryForm from "./EntryForm";
import { getJournalEntry } from "../../utilities/journal/getJournal";
import { updateJournalEntry } from "../../utilities/journal/setJournal";
import { useNotifications } from "@mantine/notifications";


const EditEntry = (props) => {
    const slug = props.slug;
    const navigate = useNavigate();
    const [entry, setEntry] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const notifications = useNotifications();

    useEffect(()=>{
        getEntry();
    },[]);

    const getEntry = async()=>{
        setLoading(true);
        const entryObj = await getJournalEntry(slug);
        console.log(entryObj);
        if(entryObj){
            setEntry(entryObj);
        }else{
            setError(true);
        }
        setLoading(false);
    }

    const formSubmitHandler = async (title, date, body, location, imageUrl, emotion)=>{
        console.log("I am edit entry. let me start the save process.");
        const result = await updateJournalEntry(title, date, body, location, slug, imageUrl, emotion, entry.id);
        if(result === "DATABASE_POST_SUCCESS"){
            notifications.showNotification({
                title: 'Rejoice!',
                message: 'The Entry Has Been Successfuly Updated!',
                autoClose: 5000,
                color: 'green',
            });
            navigate(`/entry/${slug}`,{replace:true});
        }else if(result === "DATABASE_POST_ERROR"){
            notifications.showNotification({
                title: 'Oh Boy!',
                message: 'The database hates you and did not update the entry!',
                autoClose: 5000,
                color: 'red',
            });
        }
        else if(result === 'DECRYPTION_ERROR'){
            notifications.showNotification({
                title: 'Oh Boy!',
                message: 'The entry could not be encrypted. Why would that happen?!',
                autoClose: 5000,
                color: 'red',
            });
        }
    }

    const discardClickHandler =()=>{
        navigate(`/entry/${slug}`,{replace:true});
      }
    

  return (
    <>
        <LoadingOverlay visible={loading} />
        {entry && !error && <EntryForm entry={entry}  onSubmitHandler={formSubmitHandler} onDiscardClicked={discardClickHandler} /> }
    </>
  )
}

export default EditEntry