import React from 'react'
import DashboardLayout from '../components/layout/DashboardLayout'
import WriteEntry from '../components/write/WriteEntry'

const Create = () => {
  return (
    <DashboardLayout>
        <WriteEntry />
    </DashboardLayout>
  )
}

export default Create