import { collection, addDoc, doc, setDoc } from "firebase/firestore"; 
import db from "./firebase";

export const firebaseSaveJournalEntry = async(journalEntry)=>{
    try {
        const docRef = await addDoc(collection(db, "entries"), journalEntry);
        return docRef.id;
      } catch (e) {
        console.error("Error adding document: ", e);
        return null;
    }
}

export const firebaseUpdateJournalEntry = async(id, newEntry)=>{
    console.log("The id is:",id);
    console.log("New Entry:",newEntry);
    await setDoc(doc(db, "entries", id), newEntry);
    return 1;
}
