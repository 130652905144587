import { ActionIcon, useMantineColorScheme } from '@mantine/core';
import { RiMoonClearLine, RiSunLine} from "react-icons/ri";


const DarkModeToggle = () => {
    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const dark = colorScheme === 'dark';

    return (
        <ActionIcon
        size="xl"
            variant="light"
            color={dark ? 'yellow' : 'blue'}
            onClick={() => toggleColorScheme()}
            title="Toggle Dark Mode"
        >
        {dark ? (
          <RiSunLine style={{ width: 18, height: 18 }} />
        ) : (
          <RiMoonClearLine style={{ width: 18, height: 18 }} />
        )}
      </ActionIcon>
    )
}

export default DarkModeToggle