import { getSingleEntry} from '../firebase/getEntries';
import { decryptData } from '../encrypt/handleData';

export const getJournalEntry = async(slug)=>{
    const entryObj = await getSingleEntry(slug);
    if(entryObj){
       const decryptedBody = await decryptData(entryObj.body, entryObj.flavor);
       if(decryptedBody && decryptedBody.data){
            return {
                ...entryObj,
                body: decryptedBody.data
            }
       }else
        return "DECRYPTION_ERROR";
    }else{
        return "DATABASE_FETCH_ERROR";
    }
}