import { Select } from '@mantine/core';
import { forwardRef } from 'react';
import emotionList from '../../utilities/emotions/emotionList';
import EmotionGrid from '../UI/EmotionGrid';


const SelectItem = forwardRef(
    ({ image, label, description, ...others}, ref) => (
      <div ref={ref} {...others}>
          <EmotionGrid image={image} label={label} description={description} />
      </div>
    )
);

const EmotionSelect = (props) => {

  let selectedEmotion = null;
  if(props.initialValue){
    selectedEmotion=props.initialValue;
  }

  return (
    <Select
        label="How Are You Feeling?"
        placeholder='Pick One'
        itemComponent={SelectItem}
        data={emotionList}
        value={selectedEmotion}
        onChange={props.onEmotionSelectChange}
        searchable
        nothingFound="Unknown Emotion"
        filter={(value, item) =>
            item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
            item.description.toLowerCase().includes(value.toLowerCase().trim())
        }
    />

  )
}

export default EmotionSelect