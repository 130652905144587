import myConfig from '../../myConfig';
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";


export const logIntoFireBase = async (email, password) => {
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return {
        status: true
      };
    }catch(error){
      if(error.code === "auth/wrong-password"){
        return {
                  status: false,
                  source:"PASSWORD",
                  message: "Password Mismatch! BEEP BOOP"
              };
      }
      if(error.code === "auth/user-not-found"){
        return {
                  status: false,
                  source:"EMAIL",
                  message: "Unknown Email! BEEP BOOP"
              };
      }
      return {
                status: false,
                source:"UNKNOWN",
                message: error.message
            };

    }   
};


export const logOutOfFireBase = async()=>{
  const auth = getAuth();
  try{
    await signOut(auth);
  }catch(error){
    console.log(error);
  }
}

