import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DashboardLayout from '../components/layout/DashboardLayout';
import EntryDetail from '../components/journal/EntryDetail';
import { getSingleEntry } from '../utilities/firebase/getEntries';
import { decryptData } from '../utilities/encrypt/handleData';
import deleteSingleDoc from '../utilities/firebase/deleteEntry';
import useStorage from '../hooks/use-storage';

const ViewEntry = () => {
    //GET PARAMS AND INITALIZE USE NAVIAGATE FOR LATER
    const slug = useParams().slug;
    const navigate = useNavigate();

    //INITALIZE SOME NECESSARY STATES
    const [entry,setEntry]=useState({});
    const [isLoading,setIsLoading]=useState(false);
    const [error,setError]=useState(null);

    //SET UP THE USE STORAGE HOOK TO DELETE IMAGE LATER
    const {deleteImage} = useStorage();


    useEffect(()=>{
        getEntry();
    },[]);

    const getEntry = async() =>{
        setIsLoading(true);
        const encryptedEntry = await getSingleEntry(slug);
        if(encryptedEntry && encryptedEntry.flavor){
            const decryptedEntry = await decryptData(encryptedEntry.body, encryptedEntry.flavor);
            if(decryptedEntry){
                setEntry({
                    ...encryptedEntry,
                    body:decryptedEntry.data
                });
            }else{
                setError("There was an error decrypting the data!")
            }
        }else{
            setError("Could Not Get Entry From The Database!")
        }
        setIsLoading(false);
    }

    const deleteEntry = async()=>{
        await deleteSingleDoc(entry.id);
        if(entry.url){
          await deleteImage(null, entry.url);
        }
        navigate('/home',{replace:true});
    }

    const editEntry = ()=>{
        navigate(`/entry/${slug}/edit`);
    }

    return (
        <DashboardLayout>
           <EntryDetail 
                style={{maxWidth:"100%"}} 
                entry={entry} 
                isLoading={isLoading} 
                error={error} 
                onDeleteClicked={deleteEntry}
                onEditClicked={editEntry}
            />
        </DashboardLayout>
    )
}

export default ViewEntry