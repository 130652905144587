import {Group, Avatar, Text } from '@mantine/core';

const EmotionGrid = (props) => {
  return (
    <Group noWrap>
    <Avatar src={props.image} />

    <div>
      <Text>{props.label}</Text>
      <Text size="xs" color="dimmed">
        {props.description}
      </Text>
    </div>
    </Group>
  )
}

export default EmotionGrid