import { useRef, useState } from "react";
import { TextInput, PasswordInput, Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { logIntoFireBase } from "../../utilities/firebase/auth";
import ShowError from "../UI/ShowError";
import styles from './LoginForm.module.css';
import {AiOutlineLogin} from  "react-icons/ai";
import {VscLock} from  "react-icons/vsc";

const LoginForm = () => {
  const emailInputRef = useRef(null);
  const PasswordInputRef = useRef(null);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loginProcessStarted, setLoginProcessStarted] = useState(false);

  const logInHandler = async (event) => {
    event.preventDefault();
    setLoginProcessStarted(true);
    const emailInputVal = emailInputRef.current.value;
    const passwordInputVal = PasswordInputRef.current.value;
    const loginData = await logIntoFireBase(emailInputVal, passwordInputVal);
    if (loginData.status) {
      navigate("/home", { replace: true });
    } else {
      setError(loginData);
    }
    setLoginProcessStarted(false);
  };

  return (
    <form onSubmit={logInHandler} className={styles.form} style={{width:"50%", margin:"auto"}}>
      <h3 className={styles.heading}>Log In Required <VscLock /></h3>
      {error && !error.status && error.source === "UNKNOWN" && (
        <ShowError message={error.message} />
      )}
      <TextInput
      label="Username"
        style={{marginBottom:"20px"}}
        error={
          error && !error.status && error.source === "EMAIL"
            ? error.message
            : false
        }
        ref={emailInputRef}
        size="md"
        placeholder={"tony@avengers.com"}
        required
      />
      <PasswordInput
        label="Password"
        error={
          error && !error.status && error.source === "PASSWORD"
            ? error.message
            : false
        }
        ref={PasswordInputRef}
        size="md"
        placeholder="crocodile"
        required
      />
      <Button
      style={{marginTop:"1em"}}
        type="submit"
        color="cyan"
        size="md"
        uppercase
        className={styles['login-button']}
        loading={loginProcessStarted}
      >
        <span style={{marginRight:'10px'}}>Lets Go!</span> <AiOutlineLogin />
      </Button>
    </form>
  );
};

export default LoginForm;
