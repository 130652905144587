import { Card, Grid, TextInput, Button, useMantineColorScheme } from "@mantine/core";
import {useState, useEffect} from "react";
import BodyEditor from "./RichTextEditor";
import { DatePicker } from "@mantine/dates";
import EmotionSelect from "./EmotionSelect";
import styles from "./EntryForm.module.css";
import ImageUploader from "../UI/ImageUploader";

const EntryForm = (props) => {
    const [title, setTitle] = useState("");
    const [date, setDate] = useState(new Date().toLocaleDateString('en-US'));
    const [location, setLocation] = useState("");
    const [body, setBody]= useState('');
    const [emotion, setEmotion]= useState(null);
    const [imageUrl, setImageUrl]=useState(null);
    const { colorScheme } = useMantineColorScheme();
    const dark = colorScheme === 'dark';

    const journalEntry = props.entry;

    useEffect(()=>{
        if(journalEntry){
            setTitle(journalEntry.title);
            setDate(journalEntry.date);
            setLocation(journalEntry.location);
            setImageUrl(journalEntry.url);
            setBody(journalEntry.body);
            setEmotion(journalEntry.emotion); 
        }
    },[journalEntry]);


    const onSubmitHandler =(event)=>{
        event.preventDefault();
        if(title && date && body){
            console.log({title, date, body, location, imageUrl, emotion});
            props.onSubmitHandler(title, date, body, location, imageUrl, emotion);
        }else{
            // Todo: Add validation steps here
            console.log("Validation failure");
        }
    }

    const emotionSelectChangeHandler = (emotionName)=>{
        setEmotion(emotionName);
    }

    return (
        <form onSubmit={onSubmitHandler}>
            <Grid>
                <Grid.Col lg={8} sm={12}>
                    <Card shadow="sm" padding="xl" style={{zIndex:100, overflow:"visible"}}>
                        <BodyEditor setEditorContent={setBody} initialContent={body} />

                    </Card>
                </Grid.Col>
                <Grid.Col lg={4} sm={12}>
                <Card
                    style={{ height: "100%" }}
                    shadow="sm"
                    padding="xl"
                    >
                        <TextInput 
                            placeholder="A Sunny Day" 
                            label="Topic:" 
                            value={title || ""}
                            onChange={(event)=>setTitle(event.currentTarget.value)}
                        />
                        <TextInput
                            className={styles['card-item']}
                            placeholder="Uranus"
                            label="The Location:"
                            value={location || ""}
                            onChange={(event)=>setLocation(event.currentTarget.value)}
                        />
                        <DatePicker
                            className={styles['card-item']}
                            label="Date:"
                            defaultValue={new Date()}
                            placeholder="5th of September"
                            value={date}
                            onChange={setDate}
                        />
                        <div className={styles['card-item']}>
                            <EmotionSelect onEmotionSelectChange={emotionSelectChangeHandler} initialValue={emotion} />
                        </div>
                        <div className={styles['card-item']}>
                            <ImageUploader setUrl={setImageUrl} url={imageUrl} />
                        </div>

                        <div className={styles['final-buttons']}>
                            <Button 
                                type="submit" variant={dark?"filled" : "light"} color="teal" uppercase>
                            Save
                            </Button>
                            <Button variant={dark?"filled" : "light"} color="red" uppercase onClick={props.onDiscardClicked}>
                            Discard
                            </Button>
                        </div>
                    </Card>
                </Grid.Col>
            </Grid>
        </form>
    );
};

export default EntryForm;
